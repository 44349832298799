<template>
<p>signinpage</p>
</template>

<script>
export default {
  name: 'Signin',
  data(){
    return {
    }
  },
  created() {
      console.log("signin")
      window.location.replace(`https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.VUE_APP_CLIENT_ID}&response_type=code&redirect_uri=${encodeURI(process.env.VUE_APP_REDIRECT_URI)}&response_mode=query&scope=offline_access%20${process.env.VUE_APP_SCOPE}%20mail.read&state=12345`);
      // window.location.replace('https://auth-tlic.tlic.cmu.ac.th/online-exam/signin')
  },
}
</script>
